<template>
  <crud-list
    :titulo-pesquisa="tituloPesquisa"
    :headers="headers"
    :items="items"
    :total-registros="totalRegistros"
    :paginacao.sync="pagination"
    @buscar-event="buscar"
    @novo-event="novo"
  >
    <template
      slot="items"
      slot-scope="{ item }"
    >
      <td
        class="justify-left btnEditar cursorPointer"
        @click="editar(item.item)"
      >
        {{ item.item.nome }}
        <v-icon small>
          mdi-pencil
        </v-icon>
      </td>
      <td class="hidden-sm-and-down justify-left">
        {{ item.item.apelido }}
      </td>
      <td class="hidden-sm-and-down justify-left">
        {{ item.item.tipoPessoa }}
      </td>
      <td class="hidden-sm-and-down justify-left">
        {{ item.item.cpfOuCnpj }}
      </td>
      <td class="hidden-sm-and-down justify-center">
        {{ item.item.bloco }}
      </td>
      <td class="hidden-sm-and-down justify-center">
        {{ item.item.apartamento }}
      </td>
      <td class="hidden-md-and-up justify-center">
        {{ item.item.bloco }} - {{ item.item.apartamento }}
      </td>
    </template>
  </crud-list>
</template>

<script>

  import PessoasService from '@/services/PessoasService'

  export default {
    data: () => ({
      tituloPesquisa: 'Pessoas',
      totalRegistros: 0,
      pagination: {},
      headers: [
        {
          text: 'Nome',
          value: 'nome'
        },
        {
          text: 'Apelido',
          value: 'apelido',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Tipo',
          value: 'tipoPessoa',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'CPF/CNPJ',
          value: 'cpfOuCnpj',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Bloco',
          value: 'bloco',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Apartamento',
          value: 'apartamento',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Bloco/Apto',
          value: 'bloco',
          class: 'hidden-md-and-up'
        }
      ],
      items: [],
      loading: false,
      itemRemover: {}
    }),
    created () {
      this.$root.$emit('alteraMostraPesquisa', true)
      this.$root._events.pesquisa = []
      this.$root.$on('pesquisa', this.efetuarPesquisa)
    },
    methods: {
      efetuarPesquisa (pesquisa) {
      },
      buscar () {
        this.loading = true
        PessoasService.findAll(this.pagination)
          .then((pessoas) => {
            if (pessoas && pessoas.data && pessoas.data.rows) {
              this.items = pessoas.data.rows
            }
            if (pessoas && pessoas.data) {
              this.totalRegistros = pessoas.data.count
            } else {
              this.totalRegistros = 0
            }
          })
          .catch(err => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Erro ao buscar as pessoas cadastradas: \n' + err
            })
          }).finally(() => {
            this.loading = false
          })
      },
      novo () {
        this.$router.push({
          name: 'Cadastro de Pessoa',
          params: null
        })
      },
      editar (item) {
        if (item && item.id) {
          const url = '/pessoaForm/' + item.id
          this.$router.push(url)
        }
      }
    }
  }
</script>

<style>
</style>
